import * as Sentry from "@sentry/react";
import {useCallback, useEffect, useState} from "react";
import Booking from "@/components/booking.tsx";
import {IGroup, IPTSession, IUser} from "@/types/type.ts";
import Person from "@/components/person.tsx";
import {Confirm} from "@/components/confirm.tsx";
import {scrollTop} from "@/utils/goToTop.ts";
import ReactGA from "react-ga4";
import mixpanel from "mixpanel-browser";
import {Username} from "@/components/username.tsx";
import {getPTSession, homeGroup, me} from "@/apis/api.tsx";
import {GymPicker} from "@/components/gym-picker.tsx";
import {Payment} from "@/components/payment.tsx";
import {BackButton} from "@/components/commons/back-button.tsx";
import {Wrapper} from "@/components/commons/Wrapper";
import {FaSpinner} from "react-icons/fa6";
import {ProductSelector} from "@/components/product-selector.tsx";
import {TrialExplainer} from "@/components/trial-explainer.tsx";

enum Step {
    LOGIN = 1,
    REGISTER = 2,
    PICK_GYM = 3,
    PICK_SESSION = 4,
    TRIAL = 5,
    PICK_MEMBERSHIP = 6,
    PAYMENT = 7,
    CONFIRM = 8,
    LOADING = 9
}

function HomePage() {
    ReactGA.initialize(import.meta.env.VITE_GA4_ID, {testMode: import.meta.env.VITE_ENV !== 'production'});
    const [username, setUsername] = useState('');
    const [user, setUser] = useState<IUser | null>(null);
    const [step, setStep] = useState(0);
    const [ptSession, setPtSession] = useState<IPTSession | null>(null)
    const [group, setGroup] = useState<IGroup | null>(null);
    const [homeGymName, setHomeGymName] = useState('');
    const [blockPurchase, setBlockPurchase] = useState<boolean | null>(null);
    // const [setupIntentId, setSetupIntentId] = useState('');

    const queryParameters = new URLSearchParams(window.location.search);
    const campaignSource = queryParameters.get("utm_source");
    const campaignMedium = queryParameters.get("utm_medium");
    const campaignName = queryParameters.get("utm_campaign");
    const referralCode = queryParameters.get("referral_code");
    const homeGymParam = queryParameters.get("home-gym");
    const ptSessionId = queryParameters.get("pt_session");
    const setupIntent = queryParameters.get("setup_intent");
    const blockPurchaseParam = queryParameters.get("block_purchase");

    const token = localStorage.getItem('AuthToken');

    useEffect(() => {
        if (homeGymParam) {
            setHomeGymName(homeGymParam);
        }
    }, [homeGymParam]);

    useEffect(() => {
        if (blockPurchaseParam) {
            setBlockPurchase(blockPurchaseParam === 'true');
        }
    }, [blockPurchaseParam]);


    const fetchMe = useCallback(() =>
            me()
                .then((response) => setUser(response.data))
                .catch(e => {
                    console.error(e);
                    Sentry.captureException(e);
                    localStorage.clear();
                    window.location.href = '/';
                })
        , []);

    const fetchHomeGroup = useCallback(() => {
        if (!user) {
            return;
        }
        return homeGroup(user.id)
            .then((response) => {
                if (!response.data) {
                    return null;
                }
                setHomeGymName(response.data.display_name);
                setGroup(response.data);
                return response.data;
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.error(error);
            });
    }, [user]);

// hook to check if user is logged in
    useEffect(() => {
        console.log('checking if user is logged in');
        if (token && !user) {
            console.log('getting logged in user');
            fetchMe();
        }
        if (user && !group) {
            console.log('getting home gym');
            fetchHomeGroup();
        }
    }, [fetchHomeGroup, fetchMe, group, token, user]);

    const fetchPTSession = useCallback((ptSessionId: number) => {
        console.log('getting pt session');
        return getPTSession(ptSessionId)
            .then((response) => setPtSession(response.data))
            .catch((error) => {
                Sentry.captureException(error);
                console.error(error);
            });
    }, []);

    // hook to check if this is a stripe redirect
    useEffect(() => {
        if (step === Step.PAYMENT) {
            console.log('bailing, we\'re already on the payment step');
            return;
        }
        if (ptSessionId && setupIntent && +ptSessionId > 0) {
            setStep(Step.LOADING);
            console.log('fetching data to place order');
            fetchMe()
                .then(() => fetchPTSession(+ptSessionId).then(() => {
                    console.log('setting step to payment');
                    setStep(Step.PAYMENT);
                }))
                .catch((e) => {
                    console.error(e);
                    Sentry.captureException(e);
                    console.log('setting step to login');
                    setStep(Step.LOGIN);
                });
        }

    }, [fetchMe, fetchPTSession, ptSession, ptSessionId, setupIntent]);

    function getReadableSession() {
        let slot: string = "";
        if (ptSession) {
            const date = new Date(ptSession.date);
            // get the day of the week
            const day = date.getDay();
            if (day === 0) {
                slot = "Sunday";
            } else if (day === 1) {
                slot = "Monday";
            } else if (day === 2) {
                slot = "Tuesday";
            } else if (day === 3) {
                slot = "Wednesday";
            } else if (day === 4) {
                slot = "Thursday";
            } else if (day === 5) {
                slot = "Friday";
            } else if (day === 6) {
                slot = "Saturday";
            }
            // get the time of the day
            const hours = date.getHours();
            const minutes = date.getMinutes().toString().padStart(2, '0');
            slot += `, ${hours}:${minutes}`;
            if (ptSession?.creator?.name) {
                slot += ` with ${ptSession?.creator?.name}`;
            }
        }
        return slot;
    }

    const handleLogin = (username: string, user: IUser | undefined) => {
        setUsername(username);
        scrollTop()
        if (!user) {
            console.log('setting step to register');
            setStep(Step.REGISTER);
            return;
        }
        // skip to session selection
        // store logged in user
        setUser(user);
        fetchHomeGroup()
            ?.then((group: IGroup | null) => {
                if (!group) {
                    console.log('setting step to pick gym');
                    setStep(Step.PICK_GYM);
                    return;
                }
                // pick a starting session
                console.log('setting step to pick session');
                setStep(Step.PICK_SESSION);
            })
            .catch((e) => {
                console.error(e);
                Sentry.captureException(e);
                console.log('setting step to pick gym');
                setStep(Step.PICK_GYM);
            });
    }

    const handleRegister = (user: IUser) => {
        setUser(user);
        scrollTop()
        console.log('setting step to pick gym');
        setStep(Step.PICK_GYM);
    }

    const handleHomeGymSelection = (group: IGroup) => {
        scrollTop();
        setGroup(group);
        setHomeGymName(group.name);
        console.log('setting step to pick membership');
        setStep(Step.PICK_SESSION);
    }

    const handleMembershipSelection = (isBlockPurchase: boolean) => {
        setBlockPurchase(isBlockPurchase);
        scrollTop();
        console.log('setting step to pick session');
        setStep(Step.PAYMENT);
    }

    const handlePtSessionSelection = (ptSession: IPTSession) => {
        scrollTop();
        setPtSession(ptSession);
        console.log('setting step to payment');
        setStep(Step.TRIAL);
    }

    let component;
    switch (step) {

        case Step.REGISTER: // sign up
            if (user) {
                console.log('setting step to pick gym');
                setStep(Step.PICK_GYM);
                break;
            }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Register Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: null,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "register",
                elementValue: null,
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = <Wrapper>
                <div className={"max-w-[529px]  mx-auto"}>
                    <Person nextStep={handleRegister} username={username} campaignData={{campaignName, campaignMedium, campaignSource}}/>
                </div>
            </Wrapper>
            ;
            break;
        case Step.PICK_MEMBERSHIP: // pick a membership
            if (!user) {
                console.log('setting step to login');
                setStep(Step.LOGIN);
            }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Membership Picker Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: null,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "select-membership",
                elementValue: null,
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = <div className={`relative w-full text-white bg-[#54B4B8] min-h-screen`}>
                <div className={"flex flex-col min-h-screen"}>
                    <div className={'mt-6 max-w-4xl mx-auto w-full px-5'}>
                        <BackButton callback={() => setStep(Step.TRIAL)} className={'mb-3'}/>
                    </div>
                    <ProductSelector nextStep={handleMembershipSelection} isBlockPurchase={blockPurchase}/>
                </div>
            </div>
            break;
        case Step.PICK_GYM: // pick a gym
            if (!user) {
                console.log('setting step to login');
                setStep(Step.LOGIN);
            }
            // if (group) {
            //     console.log('setting step to pick session');
            //     setStep(Step.PICK_SESSION);
            // }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Home Gym Selector'});
            component = <Wrapper>
                <div className={"max-w-[529px] mx-auto"}>
                    <GymPicker nextStep={handleHomeGymSelection} homeGym={homeGymName}/>
                </div>
            </Wrapper>
            ;
            break;
        case Step.TRIAL:
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Trial Explainer Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: null,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "trial-explainer",
                elementValue: null,
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = <div className={`relative max-w-[529px]  mx-auto text-white bg-[#54B4B8] min-h-screen`}>
                <div className={'mt-6 max-w-[529px] mx-auto px-5'}>
                    <BackButton callback={() => setStep(Step.PICK_SESSION)} className={'mb-3'}/>
                </div>
                <TrialExplainer nextStep={() => setStep(Step.PICK_MEMBERSHIP)}/>
            </div>
            break;
        case Step.PICK_SESSION: // pick a session
            if (!user) {
                console.log('setting step to login');
                setStep(Step.LOGIN);
            }
            if (!group) {
                console.log('setting step to pick gym');
                setStep(Step.PICK_GYM);
                return;
            }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Session Picker Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: null,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "select-gym",
                elementValue: homeGymName,
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = (<Wrapper>
                    <div className={"max-w-[529px]  mx-auto"}>
                        <div>
                            <BackButton callback={() => setStep(Step.PICK_GYM)} className={'mb-3'}/>
                        </div>
                        <Booking group={group} nextStep={handlePtSessionSelection} selectedPTSession={ptSession}/>
                    </div>
                </Wrapper>
            );
            break;
        case Step.PAYMENT: // payment
            if (!user) {
                console.log('setting step to login');
                setStep(Step.LOGIN);
                return;
            }
            if (!ptSession) {
                console.log('setting step to pick session');
                setStep(Step.PICK_SESSION);
                return;
            }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Payment Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: ptSession?.creator?.name,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "select-training-time-and-pt",
                elementValue: getReadableSession(),
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = <Wrapper>
                <div className={"max-w-[529px]  mx-auto"}>
                    <div>
                        <BackButton callback={() => setStep(Step.PICK_SESSION)} className={'mb-3'}/>
                    </div>
                    <Payment ptSession={ptSession} nextStep={() => {
                        fetchMe()?.then(() => {
                            console.log("setting step to confirm");
                            setStep(Step.CONFIRM);
                        })
                    }}
                             user={user}
                             setupIntentId={setupIntent}
                             referralCode={referralCode}
                             campaignMedium={campaignMedium}
                             campaignName={campaignName}
                             blockPurchase={blockPurchase}
                             campaignSource={campaignSource}/>
                </div>
            </Wrapper>
            break;
        case Step.CONFIRM:
            if (!user) {
                console.log('setting step to login');
                setStep(Step.LOGIN);
                return;
            }
            // if (!ptSession) {
            //     console.log('setting step to pick session');
            //     setStep(Step.PICK_SESSION);
            //     return;
            // }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Confirmation Page'});
            mixpanel.track('booking', {
                gymName: homeGymName,
                personalTrainer: ptSession?.creator?.name,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "payment-complete",
                elementValue: "fixed",
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = (<Wrapper>
                    <div className={"max-w-[529px]  mx-auto"}>
                        <Confirm
                            ptSessionSelected={ptSession}
                            referralCode={user?.referral_code ?? ""}
                        />
                    </div>
                </Wrapper>
            )
            break;
        case Step.LOADING:
            component = <Wrapper><FaSpinner className="animate-spin m-auto w-6 h-6"/></Wrapper>;
            break;
        case Step.LOGIN:
        default:
            if (user) {
                console.log('setting step to pick gym');
                setStep(Step.PICK_GYM);
                break;
            }
            ReactGA.send({hitType: 'pageview', page: '/', 'title': 'Passcode Page'});
            mixpanel.track('booking', {
                gymName: null,
                personalTrainer: null,
                bookingType: "new",
                screenName: "booking-screen",
                elementAction: "tap",
                elementName: "passcode-screen",
                elementValue: null,
                workoutCreator: null,
                workoutName: null,
                workoutType: null,
                product: 'web'
            });
            component = <Wrapper className={'wrapper'} bgImage={true}><Username nextStep={handleLogin}/></Wrapper>;
    }

    return <>{component}</>;
}

export default HomePage
