interface TrialExplainerProps {
    nextStep: () => void
}

export const TrialExplainer = ({nextStep}: TrialExplainerProps) => {
    return <div className={'text-white flex-grow lg:px-12 px-5'}>
        <div className={'text-center text-white md:mb-6 mb-14'}>
            <h3 className={'font-bold text-3xl mb-3'}>You've unlocked a limited-time trial offer!</h3>
            <div className={"bg-amber-500 p-3 rounded w-80 md:w-96 mx-auto mb-6 relative shadow-2xl"}>
                <div className={'ribbon text-white font-bold'}>Offer</div>
                <h1 className={'font-bold text-5xl text-white px-2'}>First session<br/>£5</h1>
            </div>
            <h3 className={'text-xl text-gray-100 mb-3'}>You get your first session for £5.</h3>
            <h3 className={'text-xl text-gray-100 mb-3'}>You can cancel anytime before your second session begins to
                avoid being charged the full amount.</h3>
            <h3 className={'text-xl text-gray-100 mb-3'}>Select your plan on the next page.</h3>
        </div>
            <hr className={'my-3 md:w-2/3 lg:w-1/2 xl:w-1/3 mx-auto border-gray-200'}/>
        <button className={`mt-5 w-full text-center text-white py-2 rounded-lg 
                                 bg-[#ec6730] cursor-pointer font-bold                                
                                 disabled:bg-black`}
                onClick={() => nextStep()}
        >
            Select my plan
        </button>
    </div>
}
