import * as Sentry from "@sentry/react";
import {useEffect, useState} from "react";
import {getPTSessions} from "@/apis/api.tsx";
import {IGroup, IPTSession, ItemSession} from "@/types/type.ts";
import moment from 'moment-timezone';
import {LiaTimesSolid} from "react-icons/lia";
import {SessionDateBlock} from "@/components/session-date-block.tsx";
import {Loader} from "@/components/commons/Loader.tsx";
import {SessionFilter} from "@/components/session-filter.tsx";
import {Header} from "@/components/commons/Header.tsx";
import CheckCircle from "@/assets/images/CheckCirclePGBlue.svg";
import Refresh from "@/assets/images/Refresh.svg";
import SessionBuilder from "@/components/session-builder.tsx";

interface Props {
    nextStep: (ptSessionSelected: IPTSession) => void,
    group: IGroup,
    selectedPTSession?: IPTSession | null
}

const Booking = ({nextStep, group, selectedPTSession}: Props) => {
    // const linkToWaitList = import.meta.env.VITE_LINK_TO_WAITLIST ?? '/'
    const [ptSessions, setPtSessions] = useState<ItemSession[]>([])
    // const [ptId, setPtId] = useState<number | null>(null)
    const [ptSessionSelected, setPtSessionSelected] = useState<IPTSession | null>()
    const [loading, setLoading] = useState<boolean>(false)
    const minute = Math.ceil(new Date().getMinutes() / 15) * 15;
    const [pendingSession, setPendingSession] = useState({
        day: new Date().getDay(),
        hour: new Date().getHours(),
        minute: minute == 60 ? 0 : minute
    })

    useEffect(() => {
        if (selectedPTSession) {
            setPtSessionSelected(selectedPTSession)
        }
    }, [selectedPTSession]);

    useEffect(() => pullPTSessions(group.id), [group]);

    const pullPTSessions = (groupId: number, pt?: number | null) => {
        if (loading) return;
        setLoading(true)
        getPTSessions(groupId, pt)
            .then((result) => {
                if (result) {
                    const masterSalve: ItemSession[] = []
                    result.forEach((item: IPTSession) => {
                        const date = moment(item.date.toString()).utc().format('YYYY-MM-DD');
                        const index = masterSalve.findIndex(x => x.date == date)
                        if (index !== -1) {
                            masterSalve[index].sessions.push(item);
                        } else {
                            masterSalve.push({
                                date: date,
                                sessions: [item]
                            })
                        }
                    })
                    setPtSessions(masterSalve)
                }
            })
            .catch((e) => {
                console.log('Capturing exception');
                const result = Sentry.captureException(e);
                console.log('Sentry result', result);
                console.error(e);
            })
            .finally(() => setLoading(false))
    }

    const handleFilters = (ptId: number | null) => {
        // setPtId(ptId);
        setPtSessionSelected(null);
        pullPTSessions(group.id, ptId)
    };

    let sessions;
    if (ptSessions && ptSessions.length > 0) {
        sessions = ptSessions.map((item: ItemSession, key) => <SessionDateBlock key={key} item={item} sessions={item.sessions}
                                                                                onClick={(session) => setPtSessionSelected(session !== ptSessionSelected ? session : null)}
                                                                                selectedPTSession={ptSessionSelected}/>);
    } else {
        sessions = <p className={`text-center text-[#8f8c8c]`}>No sessions available</p>;
    }


    return (
        <>
            <Header title={"Pick the start date for your first session"}
                    subtitle={"Everyone starts with 1 session per week. You can always add more sessions or change your time later."}/>
            <div className={`mt-5 flex-1`}>
                <SessionFilter groupId={group.id} applyFilter={handleFilters}/>
                <div>
                    {loading ? <Loader/> : <div>{sessions}</div>}
                </div>
                {!ptSessionSelected &&
                    <div className={`mb-3 mt-8 rounded-lg bg-white border-2 px-3 py-2 border-gray-400`}>
                        <img src={Refresh} alt={'empty state'} className={'mb-4'}/>
                        <h3 className={'font-bold mb-1 text-black'}>Can't find a session that works?</h3>
                        <h1 className={'text-2xl font-bold mb-4 text-black'}>Create your own session!</h1>
                        <ul className={'text-gray-500 mb-4  leading-4 text-sm'}>
                            <li className={'flex'}>
                                <img src={CheckCircle} alt={""} className={'mr-2 inline-block'} style={{height: "24px", width: "24px"}}/>
                                <p className={'pb-2'}>
                                    We’ll match you to other members that also want the same time and a PT
                                </p>
                            </li>
                            <li className={'flex'}>
                                <img src={CheckCircle} alt={""} className={'mr-2 inline-block'} style={{height: "24px", width: "24px"}}/>
                                <p className={'pb-2'}>
                                    In the event we cannot provide the session, we’ll give you at least 48 hours notice with the option of a full refund
                                </p>
                            </li>
                        </ul>
                        <SessionBuilder
                            day={pendingSession.day}
                            hour={pendingSession.hour}
                            minute={pendingSession.minute}
                            ptSessions={ptSessions}
                            group={group}
                            onSessionBuilt={(session: IPTSession) => {
                                if (+session.status === 0 && session.day && session.hour && session.minute) {
                                    setPendingSession({
                                        day: session.day,
                                        hour: session.hour,
                                        minute: session.minute == 60 ? 0 : session.minute
                                    });
                                }
                                setPtSessionSelected(session);
                            }}/>
                    </div>
                }
            </div>
            {
                (!!ptSessionSelected && !!group) && (
                    <div className={`sticky bottom-0 left-0 bg-gray-100 border-t-8 border-t-amber-500
                        md:pt-2 pt-6 md:pb-5 pb-24 md:w-[529px] px-5
                        shadow-2xl 
                        md:mx-auto md:rounded-t-lg -mx-5`}>
                        <p className={'font-bold text-gray-800 pt-2 flex-grow'}>You have selected:</p>
                        <div className={`mt-3`}>
                            <div className={`flex justify-between items-center border-2 border-gray-300 text-gray-800  
                                    rounded-lg p-2`}>
                                    <span className={`text-xs`}>
                                        {moment(ptSessionSelected.date).utc().format("dddd, HH:mm")} with {ptSessionSelected.creator?.name ?? 'Brawn (TBC)'}
                                    </span>
                                <button onClick={() => setPtSessionSelected(null)}>
                                    <LiaTimesSolid className={`w-5 h-5 font-thin`}/>
                                </button>
                            </div>
                        </div>

                        <button className={`mt-5 w-full text-center text-white py-2 rounded-lg 
                                 bg-[#ec6730] cursor-pointer font-bold
                                 disabled:bg-black`}
                                onClick={() => nextStep(ptSessionSelected)}
                        >
                            Proceed to booking
                        </button>
                    </div>
                )
            }
        </>
    )
}

export default Booking;
