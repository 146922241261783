import {ErrorMessage, Field, Form, Formik} from "formik";
import {FaSpinner} from "react-icons/fa6";
import {IGroup, IPTSession, ItemSession} from "@/types/type.ts";
import {useState} from "react";
import {SessionItem} from "@/components/session-item.tsx";
import Calendar from "@/assets/images/CalendarBlank.svg";
import {createPendingPTSession} from "@/apis/api.tsx";


interface ISession {
    day: number,
    hour: number,
    minute: number
}

interface SessionBuilderProps {
    onSessionBuilt: (session: IPTSession) => void,
    day?: number,
    hour?: number,
    minute?: number,
    ptSessions?: ItemSession[],
    group: IGroup
}

export default function SessionBuilder({onSessionBuilt, day, hour, minute, ptSessions, group}: SessionBuilderProps) {
    const [existingSessions, setExistingSessions] = useState<IPTSession[]>([]);
    const [pendingSessionAttributes, setPendingSessionAttributes] = useState<ISession>({day: 0, hour: 0, minute: 0});

    const initialValues = {
        day: day ?? new Date().getDay(),
        hour: hour ?? new Date().getHours(),
        minute: minute ?? Math.ceil(new Date().getMinutes() / 15) * 15
    }

    function getFutureDate(dayOfWeek: number, hour: number, minute: number) {
        console.log(dayOfWeek, hour, minute);
        const now = new Date();
        const today = now.getDay(); // Sunday = 0, Monday = 1, ..., Saturday = 6
        const futureDate = new Date(now);

        // Calculate days until the next occurrence of the specified weekday
        let daysUntilNext = (dayOfWeek - today + 7) % 7;

        // Ensure at least 7 days in the future
        if (daysUntilNext === 0 || daysUntilNext < 7) {
            daysUntilNext += 7;
        }

        futureDate.setDate(now.getDate() + daysUntilNext);
        futureDate.setHours(hour, minute, 0, 0); // Set the correct time
        return futureDate;
    }

    const checkForExistingSessions = (session: ISession) => {
        let existingSessions: IPTSession[] = [];
        if (ptSessions) {
            const possible = ptSessions.find(x => (new Date(x.date)).getDay() === +session.day);
            if (possible) {
                existingSessions = possible.sessions.filter(y => {
                    const date = new Date(y.date);
                    return date.getHours() >= (+session.hour - 1) && date.getHours() <= (+session.hour + 1);
                });
            }
        }
        return existingSessions;
    }

    const buildPTSession = async (session: ISession): Promise<IPTSession> => {
        const response = await createPendingPTSession({
            day: +session.day,
            hour: +session.hour,
            minute: +session.minute,
            group_id: +group.id,
            product_id: import.meta.env.VITE_PRODUCT_ID
        });
        console.log(response.data);
        return response.data as IPTSession;
        // return {
        //     id: 0,
        //     capacity: 6,
        //     creator: {
        //         id: 0,
        //         name: "Brawn PT (TBC)",
        //         tagline: "",
        //         cover_photo_url: "",
        //         avatar_url: "",
        //         type: "user",
        //         model_type: "user"
        //     },
        //     creator_id: 0,
        //     date: date.toISOString(),
        //     group: group,
        //     group_id: group.id,
        //     model_type: 'pt_session',
        //     status: 0,
        //     day: session.day,
        //     hour: session.hour,
        //     minute: session.minute,
        // }

    }

    function formatDateToDayAndTime(date: Date) {
        console.log(date);
        const options: Intl.DateTimeFormatOptions = {weekday: 'long', hour: '2-digit', minute: '2-digit', hour12: false};
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    }


    return <div>
        {existingSessions.length > 0 &&
            <div className={'bg-amber-100 text-amber-900 p-4 rounded-lg'}>
                <div className={'flex mb-2 text-lg'}>
                    <img src={Calendar} alt={""} className={'mr-2 inline-block text-amber-600'} style={{height: "24px", width: "24px"}}/>
                    <div>
                        <h4 className={'font-bold'}>It's close!</h4>
                    </div>
                </div>
                <p className={''}>You've
                    proposed: <strong>{formatDateToDayAndTime(getFutureDate(pendingSessionAttributes.day, pendingSessionAttributes.hour, pendingSessionAttributes.minute))}</strong>
                </p>
                <p>We have sessions close to this, do any of these work for you?</p>
                <div className={`grid grid-cols-3 gap-3 py-4`}>
                    {existingSessions.map((session) => <SessionItem selected={false} session={session} onClick={() => onSessionBuilt(session)} key={session.id}/>)}
                </div>
                <p className={'font-bold text-center'}>OR</p>
                <button onClick={async () => onSessionBuilt(await buildPTSession(pendingSessionAttributes))}
                        className={'text-center text-white rounded-lg bg-[#ec6730] cursor-pointer font-bold w-full h-12 font-sans my-3'}>
                    Create session anyway
                </button>
            </div>}
        {existingSessions.length === 0 &&
            <Formik enableReinitialize={false} initialValues={initialValues} onSubmit={async (values, {setSubmitting}) => {
                console.log(values);
                setPendingSessionAttributes(values);
                setExistingSessions([]);
                setSubmitting(true);
                const existingSessions = checkForExistingSessions(values);
                if (existingSessions.length > 0) {
                    setExistingSessions(existingSessions);
                    setSubmitting(false);
                    return;
                }
                onSessionBuilt(await buildPTSession(values));
            }}>
                {({isSubmitting, errors}) => (<Form>
                    <div className={'text-gray-800'}>
                        <p className={'mb-2'}>Choose your session time</p>
                        <div className={'flex gap-3'}>
                            <div className={'w-2/3'}>
                                <Field
                                    as={"select"}
                                    placeholder={'Day'}
                                    type="number"
                                    name="day"
                                    required
                                    className={`w-full px-2 py-4 placeholder:text-gray-400 border rounded-lg bg-white text-gray-900 focus:outline-0 text-base ${errors?.day ? 'border-[#FF0002]' : ''}`}
                                >
                                    <option value={1}>Monday</option>
                                    <option value={2}>Tuesday</option>
                                    <option value={3}>Wednesday</option>
                                    <option value={4}>Thursday</option>
                                    <option value={5}>Friday</option>
                                    <option value={6}>Saturday</option>
                                    <option value={0}>Sunday</option>
                                </Field>
                                <ErrorMessage name="day">
                                    {(msg) => <span className="text-sm text-[#FF0002]">{msg}</span>}
                                </ErrorMessage>
                            </div>


                            <div className={'w-1/6'}>
                                <Field
                                    as={"select"}
                                    name="hour"
                                    placeholder={'Hour'}
                                    type={"number"}
                                    required
                                    className={`w-full px-2 py-4 placeholder:text-gray-400 border rounded-lg bg-white text-gray-900 focus:outline-0 text-base ${errors?.day ? 'border-[#FF0002]' : ''}`}
                                >
                                    <option value={6}>6</option>
                                    <option value={7}>7</option>
                                    <option value={8}>8</option>
                                    <option value={9}>9</option>
                                    <option value={10}>10</option>
                                    <option value={11}>11</option>
                                    <option value={12}>12</option>
                                    <option value={13}>13</option>
                                    <option value={14}>14</option>
                                    <option value={15}>15</option>
                                    <option value={16}>16</option>
                                    <option value={17}>17</option>
                                    <option value={18}>18</option>
                                    <option value={19}>19</option>
                                    <option value={20}>20</option>
                                </Field>
                                <ErrorMessage name="hour">
                                    {(msg) => <span className="text-sm text-[#FF0002]">{msg}</span>}
                                </ErrorMessage>
                            </div>
                            <div className={'w-1/6'}>
                                <Field
                                    as={"select"}
                                    name="minute"
                                    placeholder={'Minute'}
                                    type={"number"}
                                    required
                                    className={`w-full px-2 py-4 placeholder:text-gray-400 border rounded-lg bg-white text-gray-900 focus:outline-0 text-base ${errors?.day ? 'border-[#FF0002]' : ''}`}
                                >
                                    <option value={0}>00</option>
                                    <option value={15}>15</option>
                                    <option value={30}>30</option>
                                    <option value={45}>45</option>
                                </Field>
                                <ErrorMessage name="minute">
                                    {(msg) => <span className="text-sm text-[#FF0002]">{msg}</span>}
                                </ErrorMessage>
                            </div>
                        </div>

                        <button
                            disabled={isSubmitting}
                            className={`text-center text-white rounded-lg 
                                     bg-[#ec6730] cursor-pointer font-bold w-full h-12 font-sans
                                     disabled:bg-gray-300 disabled:cursor-none my-3`}
                            type="submit"
                        >
                            {
                                isSubmitting ? (<FaSpinner className={`animate-spin w-5 h-5 m-auto`}/>) : 'Create session'
                            }
                        </button>
                    </div>
                </Form>)}
            </Formik>}
    </div>
}
